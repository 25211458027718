import { debounce } from "debounce";
import "whatwg-fetch";

import "./src/styles/main.scss";

const resize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    //eslint-disable-next-line no-unused-expressions
    import(`intersection-observer`);
  }

  resize();
  window.addEventListener("resize", debounce(resize, 200));
};
